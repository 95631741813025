import React, { useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';

const Alerts = () => {
    const alertContext = useContext(AlertContext);

    return (
        alertContext.alerts.length > 0 &&
        alertContext.alerts.map(alert => (
            <div key={alert.id} className={`alert alert-${alert.type} d-flex justify-content-center align-items-center`}>
                <i className="fas fa-info-circle pr-3"></i> {alert.msg}
            </div>
        ))
    )
}

export default Alerts
